//公共模块接口设置
import {
  postRequest,
  //postRequestParams,
  getRequest,
} from "@/api/request"; // 导入request中创建的axios实例

const common = {
  signIn: params => {//登录接口
    return postRequest("api/?cmd=sales.login.loginPwd", params);
  },
  getUserList:params=>{
    return postRequest("api/?cmd=sales.user.get_address", params);
  },
  saveUser:(mode,params)=>{//编辑保存用户信息
    return postRequest(mode =='add' ? "api/?cmd=sales.userAdd.saveAddress" : "api/?cmd=sales.user.editAddress", params);
  },
  getProductList:params=>{
    return postRequest("api/?cmd=public.getProductList", params);
  },
  getStationDelivery:params=>{
    return postRequest("api/?cmd=public.getStationDelivery", params);
  },
  getTimeByDeliver:params=>{
    return postRequest("api/?cmd=public.getTimeByDeliver", params);
  },
  countOneOrderAmount:params=>{
    return postRequest("api/?cmd=public.countOneOrderAmount", params);
  },
  quickSendOrder:params=>{//生成订单
    return postRequest("api/?cmd=sales.order.quickSendOrder", params);
  },
  getOrederList:params=>{//订单列表
    return postRequest("api/?cmd=sales.order.getOrderList", params);
  },
  getReceiveMoneyList:params=>{//收款记录
    return postRequest("api/?cmd=sales.user.getReceiveMoneyList", params);
  },
  continuePayOrder:params=>{//继续支付
    return postRequest("api/?cmd=sales.order.continuePayOrder", params);
  },
  getPayState:params=>{//获取订单支付状态
    return postRequest("api/?cmd=sales.order.getOrderInfo", params);
  },
  buyDiscountOrder:params=>{//套餐购买
    return postRequest("api/?cmd=sales.order.buyDiscountOrder", params);
  },
  getDisOrderList:params=>{//预存订单列表
    return postRequest("api/?cmd=sales.order.getDisOrderList", params);
  },
  continuePayDisOrder:params=>{//点击继续支付优惠订单
    return postRequest("api/?cmd=sales.order.continuePayDisOrder", params);
  },
  refundDisOrder:params=>{//优惠订单退单
    return postRequest("api/?cmd=sales.order.refundDisOrder", params);
  },
  getDisOrderPayState:params=>{//获取订单支付状态
    return postRequest("api/?cmd=sales.order.getDisOrderInfo", params);
  },
  gdPositionName:location=>{//根据经纬度查询区域名称  高德
    return getRequest("regeo?location="+location+"&key=d2dade3a15beb7576849f93e8266b6c2", {},true);
  },
  getPositionName:params=>{//根据经纬度查询区域名称  高德
    return postRequest("api/?cmd=sales.location.location2Regeo",params);
  }
};

export default common;
