import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import {
  TOKEN_KEY,
} from '../utils/common'
Vue.use(Vuex)
export default new Vuex.Store({
  namespace: true,
  state: {
    userinfo:localStorage.getItem('userinfo'),//当前手机号
    isAddress:false,
    quickOrder:{},
    address:{},
    shifUser:{},//当前选择的预填用户信息
    classify:{},
    product:{},
    point:{
      latitude:"",//纬度
      longitude:"",//经度
      province:"",//省
      city:"",//市
      district:"",//区
      address:'',//详细地址
    }
  },
  mutations: {
    setquickOrder(state,obj){
      state.quickOrder = obj
    },
    setShifUser(state,obj){
      state.shifUser = obj
    },
    setProduct(state,obj){
      state.product = obj
    },
    setClassify(state,obj){
      state.classify = obj
    },
    changeAddress(state,obj){
      state.address = obj
    },
    setIsAddress(state,flag){
      state.isAddress = flag
    },
    changeUser(state,obj){
      state.userinfo = obj
    },
    setPageSize(state, obj) {
      //更新页面尺寸对象
      state.pageSizes = obj
    },
    changePoint(state, obj){
      state.point = obj
    },
    logout(state, vm) {
      Cookies.remove(TOKEN_KEY)
      localStorage.clear()
      sessionStorage.clear()
      vm.$router.replace({ name: 'login' })
    }
  }
})