/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'
import {
  MAINHOST,
  GEOHOST
} from './config'
import {
  TOKEN_KEY
} from '../utils/common'

import Cookies from 'js-cookie'
import store from '../store/store'
import vm from '../main.js'

//axios配置
axios.defaults.timeout = 150000 //超时配置
axios.defaults.retry = 3 //请求次数
axios.defaults.retryDelay = 1000

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  vm.$toast.fail('登录失效，请重新登录！')
  store.commit('logout', vm)
}
/* eslint-disable */
const formdata =(obj)=> {
  let result = ''
  for (let name of Object.keys(obj)) {
    let value = obj[name];
    result += 
    '\r\n--XXX' +
    '\r\nContent-Disposition: form-data; name=\"'+ name +'\"'+ 
    '\r\n' +
    '\r\n' + value
  }
  return result + '\r\n--XXX--'
}
/* eslint-disable */
// 请求拦截器
axios.interceptors.request.use(
  config => {
    let token = Cookies.get(TOKEN_KEY)
    config.headers = {
      "Content-Type": 'multipart/form-data; boundary=XXX',
      //'Access-Control-Allow-Headers':'Content-Type, Content-Length, Authorization, Accept, X-Requested-With , yourHeaderFeild',
      //"Access-Control-Allow-Origin":'*',
      token: token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * 请求失败后的错误统一处理
 * status 请求失败的状态码
 */
const errorHandle = (status, msg) => {
 // let clientType = localStorage.getItem('appClientType')

  // 状态码判断
  switch (status) {
    case "110111":
      toLogin()
      break
    default:
      vm.$toast.fail(msg)
  }
}
const handleReject = res => {
  errorHandle(res.data.code, res.data.msg)
  return Promise.reject(res)
}
// 响应拦截器
axios.interceptors.response.use(
  // 请求成功
  res => {
    let state = res.data.code != '110111'
    return res.status === 200 && state ?
      Promise.resolve(res) :
      handleReject(res)
  },
  error => {
    const {
      response
    } = error
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message)
      return Promise.reject(response)
    } else {
      vm.$toast.fail('服务器连接失败') // 处理断网的情况
    }
  }
)

export const postRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
        method: 'post',
        url: `${MAINHOST}${url}`,
        data: formdata(params).replace(/\+/g, '%2B')
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export const postRequestParams = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
        method: 'post',
        url: `${MAINHOST}${url}`,
        params: formdata(params).replace(/\+/g, '%2B')
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
export const postDataParams = (url, params, data) => {
  //拼接参数及data形式传参
  return new Promise((resolve, reject) => {
    axios({
        method: 'post',
        url: `${MAINHOST}${url}`,
        params: params,
        data: data
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getRequest = (url, params,state) => {
  console.log("GEOHOST="+GEOHOST,'state='+state)
  return new Promise((resolve, reject) => {
    axios({
        method: 'get',
        url: state ? `${GEOHOST}${url}` : `${MAINHOST}${url}`,
        params: state ? params : formdata(params).replace(/\+/g, '%2B')
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const deleteRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
        method: 'delete',
        url: `${MAINHOST}${url}`,
        params: params
      })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}