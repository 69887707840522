import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';

import api from "@/api/api"; // 导入api接口
import {
  router
} from "@/router/index";
import store from "@/store/store";
import 'vant/lib/index.css';
import "./assets/scss/font/iconfont.scss";
import "./assets/scss/common.scss";
Vue.config.productionTip = false

Vue.use(Vant);
Vue.prototype.$api = api; // 将api挂载到vue的原型上

let vue = new Vue({
  router,
  store,
  render: h => h(App),
  created() {}
}).$mount("#app");

export default vue;

