/**
 * 线上环境
 */
export const ONLINEHOST = "https://api.dingnai.cn/"; //业务请求
export const ONGEOHOST = 'https://restapi.amap.com/v3/geocode/'
/**
 * 测试环境
 */
export const QAHOST = 'https://test.api.dingnai.cn/' //业务请求
export const QAGEOHOST = '/amap'
/**
 * 本地调试测试环境
 */
export const DEVHOST = 'https://test.api.dingnai.cn/' //测试业务请求


export const DEVGEOHOST = '/amap'

//业务请求主机

export const MAINHOST =
  process.env.NODE_ENV === 'development' ?
  DEVHOST :
  process.env.VUE_APP_FLAG === 'pro' ?
  ONLINEHOST :
  QAHOST

export const GEOHOST =
  process.env.NODE_ENV === 'development' ?
  DEVGEOHOST :
  process.env.VUE_APP_FLAG === 'pro' ?
  ONLINEHOST :
  QAGEOHOST

/**
 * @description token在Cookie中存储的天数，默认1天
 */
export const cookieExpires = 1