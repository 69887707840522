import Vue from "vue";
import Router from "vue-router";
import {
  routers
} from "./router";
import {
  getToken
} from "@/utils/common";

Vue.use(Router);
export const router = new Router({
  base: process.env.BASE_URL,
  routes: routers,
  mode: "history" //hash,history
});

// 登陆页面路由 name
const LOGIN_PAGE_NAME = "login";

// 跳转之前
router.beforeEach((to, from, next) => {
  const token = getToken()
  // console.log(token)
  //  next();
  console.log(to)
  if(to.name == 'user-input'){
    next();
  }else{
    if (!token && to.name !== LOGIN_PAGE_NAME) {
      // 未登录且要跳转的页面不是登录页
      next({
        name: LOGIN_PAGE_NAME // 跳转到登录页
      });
    } else if (!token && to.name === LOGIN_PAGE_NAME) {
      // 未登陆且要跳转的页面是登录页
      next(); // 跳转
    } else if (token && to.name === LOGIN_PAGE_NAME) {
      // 已登录且要跳转的页面是登录页
      next({
        name: 'home' // 跳转到 首页
      });
    } else {
      if (token) {
        next();
      } else {
        next({
          name: LOGIN_PAGE_NAME
        })
      }
    }
  }
});

// 跳转之后
router.afterEach(to => {
  let str = to.path == '/login' ? '登录' : to.meta.name
  window.document.title =  str +"-订鲜奶"
});