/*
 * 公共函数方法
 */
import Cookies from "js-cookie";
// import store from "../store/store";
import { cookieExpires } from "@/api/config"; // cookie保存的天数
/**
 * 存取token
 */
export const TOKEN_KEY = "milk_cxy_token";
export const setToken = (token) => {
  Cookies.set(TOKEN_KEY, token, {
    expies: cookieExpires || 1,
  });
};
export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY);
  //const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    return token;
  } else {
    return false;
  }
};

/**
 * 从URL中解析参数
 */
export const getParams = (url) => {
  const keyValueArr = url.split("?")[1].split("&");
  let paramObj = {};
  keyValueArr.forEach((item) => {
    const keyValue = item.split("=");
    paramObj[keyValue[0]] = keyValue[1];
  });
  return paramObj;
};

/**
 * 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
 * 如果没有传入key这个参数，则判断obj对象是否有键值对
 */
export const hasKey = (obj, key) => {
  if (key) {
    return key in obj;
  } else {
    const keysArr = Object.keys(obj);
    return keysArr.length;
  }
};

/**
 * 数组json根据key进行分类归纳
 * @param {*需要分类的key名称} keyName
 * @param {*原始数组 arr
 */
export const MapByJsonKey = (keyName, arr) => {
  var map = {},
    dest = [];
  for (var i = 0; i < arr.length; i++) {
    var ai = arr[i];
    let str = ai[keyName];
    if (!map[ai[keyName]]) {
      let obj = {};
      obj[keyName] = ai[keyName];
      if (ai.regionCode == str) {
        obj.name = ai.regionName;
        obj.level = ai.regionLevel;
      }
      obj.children = [ai];
      dest.push(obj);
      map[ai[keyName]] = ai;
    } else {
      for (var j = 0; j < dest.length; j++) {
        var dj = dest[j];
        if (dj[keyName] == ai[keyName]) {
          dj.children.push(ai);
          break;
        }
      }
    }
  }
  return dest;
};

/**
 * tree树关键字值添加
 * @param {*} data
 * @param {*} titleKey
 * @param {*} valueKey
 */
export const treeDataFormat = (data, titleKey, valueKey) => {
  for (let item of data) {
    item.title = item[titleKey];
    item.value = item[valueKey];
    item.checked = item.children == null && item.checked == true ? true : false;
    if (item.children && item.children.length > 0) {
      item.children = treeDataFormat(item.children, titleKey, valueKey);
    }
  }
  return data;
};

/*树结构转数组*/
export function turnArray(data) {
  const result = [];
  data.forEach((item) => {
    const loop = (data) => {
      result.push({
        id: data.id,
        value: data.value,
        title: data.title,
      });
      let child = data.children;
      if (child) {
        for (let i = 0; i < child.length; i++) {
          loop(child[i]);
        }
      }
    };
    loop(item);
  });
  return result;
}

/*树结构删除本级以及下级数据*/
export function delTree(data, value) {
  for (var j = 0; j < data.length; j++) {
    if (data[j].id != value) {
      if (data[j].children && data[j].children.length > 0) {
        data[j].children = delTree(data[j].children, value);
      }
    } else {
      data.splice(j, 1);
    }
  }
  return data;
}


export function geoDataFormat(data,titleKey,valueKey) {
  for(let item of data){
    item.title = item[titleKey]
    item.value = item[valueKey]
    item.checked=item.children == null && item.checked == true ? true : false
    if (item.children && item.children.length > 0){
        item.children = geoDataFormat(item.children,titleKey,valueKey)
    }else{
      item.children = []
    }
}
return data
}
/**
 * 数组递归格式化（多维数组转化为一维数组
 * @param {*} data
 */
export const jsonFormat = (data) => {
  let arr = [];
  for (let item of data) {
    if (item.children && item.children.length > 0) {
      arr = arr.concat(jsonFormat(item.children));
    }
    item.children = [];
    arr.push(item);
  }
  return arr;
}
export const countTime=(curEndTime) =>{
  // 获取当前时间
  let date = new Date()
  let now = date.getTime()
  // 设置截止时间
  let endDate = new Date(curEndTime) // this.curEndTime需要倒计时的日期
  let end = endDate.getTime()
  let day,hour,min,second;
  // 时间差
  let leftTime = end - now
  // 定义变量 d,h,m,s保存倒计时的时间
  if (leftTime >= 0) {
      // 天
      day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
      // 时
      let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
      hour = h < 10 ? '0' + h : h
      // 分
      let m = Math.floor(leftTime / 1000 / 60 % 60)
      min = m < 10 ? '0' + m : m
      // 秒
      let s = Math.floor(leftTime / 1000 % 60)
      second = s < 10 ? '0' + s : s
  } else {
      day = 0
      hour = '00'
      min = '00'
      second = '00'
  }
  return {day:day,hour:hour,min:min,second:second}
  // // 等于0的时候不调用
  // if (Number(this.hour) === 0 && Number(this.day) === 0 && Number(this.min) === 0 && Number(this.second) === 0) {
  //     return
  // } else {
  // // 递归每秒调用countTime方法，显示动态时间效果,
  //     setTimeout(countTime, 1000)
  // }
  
}

/*
 *获取系统当前日期（2019/12/23）
 *@return: string
 */
 export const getCurData = (str='-') => {
  const date = new Date();
  return (
    date.getFullYear() +
    str +
    pad(date.getMonth() + 1) +
    str +
    pad(date.getDate())
  );
};
/*
 *标准时间转换成YY-MM-DD hh:MM:ss格式
 *@return: string
 */
 export const dateTimeFormat = (val) => {
  let hour = val.getHours();
  hour = hour.toString();
  if (hour.length < 2) {
    hour = "0" + hour;
  }

  let minute = val.getMinutes();
  minute = minute.toString();
  if (minute.length < 2) {
    minute = "0" + minute;
  }

  let second = val.getSeconds();
  second = second.toString();
  if (second.length < 2) {
    second = "0" + second;
  }

  return (
    val.getFullYear() +
    "-" +
    pad(val.getMonth() + 1) +
    "-" +
    pad(val.getDate()) +
    " " +
    hour +
    ":" +
    minute +
    ":" +
    second
  );
};


/**
 * 将标准时间格式话指定格式
 * @param {*需要格式化的格式例：yyyy-MM-dd} fmt
 */
 export const dateFormat = (date, fmt) => {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

/**
 * 转换补0
 * @return: string
 */
export const pad = (n) => {
  return n < 10 ? "0" + n : n;
};