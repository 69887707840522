<template>
  <div class="main-layout">
    <div class="single-page-con fullbox">
      <div class="single-page fullbox">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Main',
  props: {},
  components: {  },
  data() {
    return {}
  },
  created() {

  },
  mounted() {
  },
  methods: {
    
  },
}
</script>

<style lang="scss">
  .main-layout{
    .single-page-con {
      box-sizing: border-box;
    }
  }
</style>
