import Main from "@/views/Main.vue";
// export const notFound = {
//   path: "*", // 此处需特别注意置于最底部
//   redirect: "/403",
// };
// export const error404 = {
//   path: "/404",
//   name: "notFound",
//   component: () => import("@/views/error-page/404.vue"),
// };
export const login = {
  path: "/login",
  name: "login",
  meta: {
    name: "登录",
  },
  component: () => import("@/views/login/login.vue")
}

export const other = {
  path: "/",
  name: "otherRouter",
  redirect: "/home",
  component: Main,
  children: [
    {
      path: "/home",
      name: "home",
      meta: {
        name: "首页",
      },
      component: () => import("@/views/home/home.vue")
    },
    {
      path: "/collection",
      name: "collection",
      meta: {
        name: "收款记录",
      },
      component: () => import("@/views/collection-record/collection-record.vue")
    },
    {
      path: "/user-list",
      name: "user-list",
      meta: {
        name: "配送明细",
      },
      component: () => import("@/views/user-list/user-list.vue")
    },
    {
      path: "/user-input",
      name: "user-input",
      meta: {
        name: "个人信息",
      },
      component: () => import("@/views/user-input/user-input.vue")
    },
    {
      path: "/map-select",
      name: "map-select",
      meta: {
        name: "地图选择",
      },
      component: () => import("@/views/user-input/components/map-select.vue")
    },
    {
      path: "/pre-storage",
      name: "pre-storage",
      meta: {
        name: "鲜奶预存",
      },
      component: () => import("@/views/pre-storage/pre-storage.vue")
    },
    {
      path: "/product-list",
      name: "product-list",
      meta: {
        name: "产品列表",
      },
      component: () => import("@/views/product-list/product-list.vue")
    },
    {
      path: "/order-list",
      name: "order-list",
      meta: {
        name: "订单查询",
      },
      component: () => import("@/views/order-list/order-list.vue")
    },
    {
      path: "/package-purchase",
      name: "package-purchase",
      meta: {
        name: "套餐购买",
      },
      component: () => import("@/views/package-purchase/package-purchase.vue")
    },
    {
      path: "/quick-order",
      name: "quick-order",
      meta: {
        name: "快捷下单",
      },
      component: () => import("@/views/quick-order/quick-order.vue")
    }
  ]
};

export const routers = [login,other];
